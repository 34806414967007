import Vue from 'vue/dist/vue.esm';
import marked from 'marked';
import '../utilities/exists';

function init() {
  const quizMethods = {
    // Create an alphabetic autoincrement
    alphabetic(index) {
      const baseChar = 'A'.charCodeAt(0);
      let letters = '';

      do {
        index -= 1;
        letters = String.fromCharCode(baseChar + (index % 26)) + letters;
        index = (index / 26) >> 0;
      } while (index > 0);

      return letters;
    },
    // onChange Event
    changeEvent(e) {
      const self = this;
      let checked = false;

      if (this.userResponses[this.qIndex] !== 0) {
        checked = this.userResponses[this.qIndex] === e.target.value;
      }

      if (checked) {
        this.userResponses[this.qIndex] = 0;
        e.target.checked = false;
        checked = false;
        self.setProgress();
      } else {
        this.userResponses[this.qIndex] = e.target.value;
        // if step is last submit
        if (self.qIndex + 1 === self.totalSteps) {
          self.setProgress();
          setTimeout(() => {
            self.submit();
          }, 500);
        } else {
          self.setProgress();
          setTimeout(() => {
            self.nextQuestion();
          }, 500);
        }
      }
    },
    fetchData() {
      const self = this;
      const formId = document
        .getElementById('component__quiz')
        .getAttribute('data-form-id');
      if (formId) {
        this.formId = formId;
      }
      const baseUrl = `/umbraco/api/quiz/getquiz/${formId}`;
      // Fetch data from Typeform API
      const xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          const dataResponse = JSON.parse(this.responseText);
          const answers = [];
          const reFixMarkdown = /\*(.)+\*/gi;
          // Assign value to defaults
          self.welcomeScreen = marked(
            dataResponse.welcome_screens[0].title.replace(
              reFixMarkdown,
              '*$&*'
            ),
            { sanitize: true }
          );
          self.totalSteps = dataResponse.fields.length;

          if (self.userResponses.length === 0) {
            self.userResponses = Array.apply(
              null,
              new Array(self.totalSteps)
            ).map(Number.prototype.valueOf, 0);
          }

          // Store thankyou pages in array
          if (dataResponse.thankyou_screens.length) {
            for (let i = 0; i < dataResponse.thankyou_screens.length; i += 1) {
              const title = marked(
                dataResponse.thankyou_screens[i].title.replace(
                  reFixMarkdown,
                  '*$&*'
                ),
                { sanitize: true }
              );
              self.thankyouScreens[dataResponse.thankyou_screens[i].ref] = {
                title,
                redirect:
                  dataResponse.thankyou_screens[i].properties.redirect_url,
                button_text:
                  dataResponse.thankyou_screens[i].properties.button_text,
              };
            }
          }

          // Add questions and answers to main object
          if (dataResponse.fields.length) {
            for (let i = 0; i < dataResponse.fields.length; i += 1) {
              const title = marked(dataResponse.fields[i].title, {
                sanitize: true,
              });
              self.quiz.questions[i] = {
                ref: dataResponse.fields[i].ref,
                title,
                answers: [],
              };
              answers[i] = dataResponse.fields[i].properties.choices;
            }
          }

          // Create logic and add to main object
          if (answers.length) {
            const tmp1 = [];
            const tmp2 = [];
            for (let i = 0; i < answers.length; i += 1) {
              if (dataResponse.logic[i].actions.length) {
                for (
                  let j = 0;
                  j < dataResponse.logic[i].actions.length;
                  j += 1
                ) {
                  // Add response and score value to tmp answers array
                  if (
                    dataResponse.logic[i].actions[j].action === 'add' &&
                    self.quiz.questions[i].ref === dataResponse.logic[i].ref
                  ) {
                    tmp1[i] = {
                      choices:
                        dataResponse.logic[i].actions[j].condition.vars[1]
                          .value,
                      value:
                        dataResponse.logic[i].actions[j].details.value.value,
                    };
                  } else if (
                    dataResponse.logic[i].actions[j].action === 'jump' &&
                    self.quiz.questions[i].ref === dataResponse.logic[i].ref
                  ) {
                    // Add logic values to tmp logic array
                    if (
                      dataResponse.logic[i].actions[j].condition.op !== 'is' &&
                      dataResponse.logic[i].actions[j].condition.op !== 'always'
                    ) {
                      tmp2.push({
                        op: dataResponse.logic[i].actions[j].condition.op,
                        score:
                          dataResponse.logic[i].actions[j].condition.vars[1]
                            .value,
                        thankyou_guid:
                          dataResponse.logic[i].actions[j].details.to.value,
                      });
                    } else if (
                      dataResponse.logic[i].actions[j].condition.op === 'always'
                    ) {
                      tmp2.push({
                        op: dataResponse.logic[i].actions[j].condition.op,
                        score: 'undefined',
                        thankyou_guid:
                          dataResponse.logic[i].actions[j].details.to.value,
                      });
                    }
                  }
                }
              }

              // Set tmp answers array to main object
              for (let j = 0; j < answers[i].length; j += 1) {
                self.quiz.questions[i].answers[j] = {
                  id: answers[i][j].id,
                  label: answers[i][j].label,
                  ref: answers[i][j].ref,
                  value: 0,
                };

                if (tmp1.length) {
                  for (let k = 0; k < tmp1.length; k += 1) {
                    if (answers[i][j].ref === tmp1[k].choices) {
                      self.quiz.questions[i].answers[j].value = tmp1[k].value;
                    }
                  }
                }
              }
            }
            // Set tmp logic array to main object
            self.quiz.logic = tmp2;
          }
        }
      };
      xhttp.open('GET', baseUrl, true);
      xhttp.send();
    },
    startQuiz() {
      this.initial = false;
    },
    nextQuestion() {
      this.qIndex += 1;
      this.isPrev = false;
    },
    prevQuestion() {
      this.qIndex -= 1;
      this.isPrev = true;
    },
    stepForwardOnly() {
      this.qIndex += 1;
      this.isPrev = false;
    },
    setProgress() {
      let answered = 0;
      for (let i = 0; i < this.userResponses.length; i += 1) {
        if (this.userResponses[i] !== 0) {
          answered += 1;
        }
      }
      const progressEl = document.getElementById('progress');
      this.currentProgress = Math.floor((answered / this.totalSteps) * 100);
      progressEl.setAttribute('style', `width: ${this.currentProgress}%`);
    },
    submit() {
      let score = 0;

      // remove characters before (-) and sum value to get score
      for (let i = 0; i < this.userResponses.length; i += 1) {
        if (this.userResponses[i]) {
          score += parseInt(
            this.userResponses[i].substring(
              this.userResponses[i].indexOf('-') + 1
            ),
            10
          );
        }
      }

      // Show thank you page depending of logic
      if (this.quiz.logic.length) {
        let guid = '';
        let done = false;
        for (let j = 0; j < this.quiz.logic.length; j += 1) {
          switch (this.quiz.logic[j].op) {
            case 'greater_than':
              if (score > this.quiz.logic[j].score) {
                guid = this.quiz.logic[j].thankyou_guid;
                done = true;
              }
              break;
            case 'lower_than':
              if (score < this.quiz.logic[j].score) {
                guid = this.quiz.logic[j].thankyou_guid;
                done = true;
              }
              break;
            case 'always':
              if (this.quiz.logic[j].score === 'undefined') {
                guid = this.quiz.logic[j].thankyou_guid;
                done = true;
              }
              break;
            default:
              break;
          }
          if (done) break;
        }
        this.thankyouRef = guid;
      }
    },
    retake() {
      this.initial = true;
      this.qIndex = 0;
      this.currentProgress = 0;
      this.thankyouRef = '';
      this.userResponses = Array.apply(null, new Array(this.totalSteps)).map(
        Number.prototype.valueOf,
        0
      );
    },
  };

  const model = {
    quiz: {
      questions: [
        {
          ref: '',
          title: '',
          value: 0,
        },
      ],
      logic: [],
    },
    thankyouScreens: [],
    thankyouRef: '',
    welcomeScreen: '',
    logic: [],
    initial: true,
    qIndex: 0,
    totalSteps: 0,
    progress: 0,
    currentProgress: 0,
    score: 0,
    userResponses: [],
    isPrev: false,
    formId: 'xdHBd1',
  };

  // eslint-disable-next-line no-new
  new Vue({
    el: '#component__quiz',
    data: model,
    methods: quizMethods,
    created() {
      this.fetchData();
    },
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const quizComponent = document.getElementById('component__quiz');

  if (quizComponent) {
    init();
  }
});
